import React from "react"
import { StaticQuery, graphql } from "gatsby"
import {Helmet} from "react-helmet";
import SEO from "../components/seo";
import {Container, Row, Nav} from "react-bootstrap";
import Header from "../components/Header";
import Footer from "../components/Footer";

const title = "Resources"
export default () => (
    <StaticQuery
        query={graphql`
        {
            allWpPost(sort: {fields: date, order: DESC}) {
                nodes {
                title
                uri
                slug
                status
                date(formatString: "MMMM Do, YYYY")
                }
            }
        }
        `}
        render={data => {
            return (
                <div>
                    <Helmet
                        title={title}
                        defer={false}
                    >
                    <SEO title={title} description="Our resource library provides helpful tools and resources to home inspectors, home buyers, and home sellers."/>
                    </Helmet>
                    <Container>
                        <Header activepage="| Resources" />
                    </Container>
                    <Container className={'legacy-page-spacer'}>
                        <h1>Resources</h1>
                        <Row
                            className="justify-content-left"
                            style={{
                                paddingTop: "25px",
                                paddingBottom: "20px",
                                textAlign: "left",
                            }}
                        >

                            <Nav defaultActiveKey="/home" className="flex-column">
                                {data &&
                                data.allWpPost &&
                                data.allWpPost.nodes &&
                                data.allWpPost.nodes.map(prop => {
                                        return (
                                            <Nav.Link key={prop.uri} style={{textDecoration:'none'}} href={prop.uri}>{prop.title}</Nav.Link>
                                        )
                                    }
                                )
                                }
                            </Nav>
                        </Row>
                    </Container>
                    <Footer />
                </div>

            )
        }}
    />
)